import {
  faArrowLeft,
  faAngleDown,
  faTimes,
  faCheck,
  faX,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

export const ICONS = [faArrowLeft, faAngleDown, faTimes, faCheck, faX, faSpinner];
