import * as React from "react";

interface ISelectedTheme {
  avatar: string;
  logo: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

interface IThemeContext {
  themeQueryString: string;
  onThemeQueryStringSelect: (themeQueryString: string) => void;
  logo: string;
  onLogoSelect: (logo: string) => void;
  avatar: string;
  onAvatarSelect: (avatar: string) => void;
  logoUrl: string;
  onLogoUrlSelect: (logoUrl: string) => void;
  avatarUrl: string;
  onAvatarUrlSelect: (avatarUrl: string) => void;
  selectedThemeForm: ISelectedTheme;
  setSelectedThemeForm: (selectedThemeForm: ISelectedTheme) => void;
}
interface IThemeProviderProps {
  children?: React.ReactNode;
}

const SelectedThemeDefault: ISelectedTheme = {
  avatar: "https://preview.gobrite.io/images/avatar.png",
  logo: "https://preview.gobrite.io/images/logo.png",
  primaryColor: "#05cacc",
  secondaryColor: "#8032ed",
  tertiaryColor: "#ff6692",
}

const ThemeContext = React.createContext<IThemeContext>({
  themeQueryString: "",
  onThemeQueryStringSelect: () => {},
  logo: "",
  onLogoSelect: () => {},
  avatar: "",
  onAvatarSelect: () => {},
  logoUrl: "",
  onLogoUrlSelect: () => {},
  avatarUrl: "",
  onAvatarUrlSelect: () => {},
  selectedThemeForm: SelectedThemeDefault,
  setSelectedThemeForm: () => {},
});

export function ThemeContextProvider({ children }: IThemeProviderProps) {
  const [themeQueryString, setThemeQueryString] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [avatar, setAvatar] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState("");
  const [avatarUrl, setAvatarUrl] = React.useState("");
  const [selectedThemeForm, setSelectedThemeForm] = React.useState<ISelectedTheme>(SelectedThemeDefault);

  const onThemeQueryStringSelect = React.useCallback(
    (themeQueryString: string) => {
      setThemeQueryString(themeQueryString);
    },
    []
  );

  const onLogoSelect = React.useCallback((logo: string) => {
    setLogo(logo);
  }, []);

  const onAvatarSelect = React.useCallback((avatar: string) => {
    setAvatar(avatar);
  }, []);

  const onLogoUrlSelect = React.useCallback((logoUrl: string) => {
    setLogoUrl(logoUrl);
    setSelectedThemeForm({ ...selectedThemeForm, logo: logoUrl });
  }, []);

  const onAvatarUrlSelect = React.useCallback((avatarUrl: string) => {
    setAvatarUrl(avatarUrl);
    setSelectedThemeForm({ ...selectedThemeForm, avatar: avatarUrl });
  }, []);

  const context = React.useMemo(
    () => ({
      themeQueryString,
      onThemeQueryStringSelect,
      logo,
      onLogoSelect,
      avatar,
      onAvatarSelect,
      logoUrl,
      onLogoUrlSelect,
      avatarUrl,
      onAvatarUrlSelect,
      selectedThemeForm,
      setSelectedThemeForm,
    }),
    [
      themeQueryString,
      onThemeQueryStringSelect,
      logo,
      onLogoSelect,
      avatar,
      onAvatarSelect,
      logoUrl,
      onLogoUrlSelect,
      avatarUrl,
      onAvatarUrlSelect,
      selectedThemeForm,
      setSelectedThemeForm,
    ]
  );

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
}

export function useThemeContext() {
  const {
    themeQueryString,
    onThemeQueryStringSelect,
    logo,
    onLogoSelect,
    avatar,
    onAvatarSelect,
    logoUrl,
    onLogoUrlSelect,
    avatarUrl,
    onAvatarUrlSelect,
    selectedThemeForm,
    setSelectedThemeForm,
  } = React.useContext(ThemeContext);

  return React.useMemo(
    () => ({
      themeQueryString,
      onThemeQueryStringSelect,
      logo,
      onLogoSelect,
      avatar,
      onAvatarSelect,
      logoUrl,
      onLogoUrlSelect,
      avatarUrl,
      onAvatarUrlSelect,
      selectedThemeForm,
      setSelectedThemeForm,
    }),
    [
      themeQueryString,
      onThemeQueryStringSelect,
      logo,
      onLogoSelect,
      avatar,
      onAvatarSelect,
      logoUrl,
      onLogoUrlSelect,
      avatarUrl,
      onAvatarUrlSelect,
      selectedThemeForm,
      setSelectedThemeForm,
    ]
  );
}
