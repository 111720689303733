import { ITheme } from "@brite-inc/ui-remote-transport-lms";

export const themes: ITheme[] = [
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "1",
    name: "Default",
    primary: "#05cacc",
    primaryAlt: "#08a9f2",
    primaryHover: "#18a4a5",
    primaryLight: "#05cacc80",
    secondary: "#8032ed",
    secondaryAlt: "#1b0a33",
    secondaryHover: "#46128f",
    secondaryLight: "#b085ec",
    tertiary: "#ff6692",
    tertiaryHover: "#b3318f",
    tertiaryLight: "#ff669280",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "2",
    name: "Blue",
    primary: "#20B4B5",
    primaryAlt: "#8c4d4d",
    primaryHover: "#cd4919",
    primaryLight: "#f15d2880",
    secondary: "#2B8CE1",
    secondaryAlt: "#2176C0",
    secondaryHover: "#4498E3",
    secondaryLight: "#7FB6E5",
    tertiary: "#045479",
    tertiaryHover: "#023953",
    tertiaryLight: "#04547980",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "3",
    name: "Green",
    primary: "#2B8CE1",
    primaryAlt: "#44498E3",
    primaryHover: "#4498E3",
    primaryLight: "#7FB6E5",
    secondary: "#0E6B5E",
    secondaryAlt: "#45847B",
    secondaryHover: "#0A9986",
    secondaryLight: "#19AC97",
    tertiary: "#94df7d",
    tertiaryHover: "#94df7d",
    tertiaryLight: "#94df7d",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "4",
    name: "Grey",
    primary: "#05cacc",
    primaryAlt: "#08a9f2",
    primaryHover: "#18a4a5",
    primaryLight: "#05cacc80",
    secondary: "#7C728A",
    secondaryAlt: "#7D738B",
    secondaryHover: "#525055",
    secondaryLight: "#A3A0A7",
    tertiary: "#a7bbca",
    tertiaryHover: "#a7bbca",
    tertiaryLight: "#a7bbca",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "5",
    name: "Yellow",
    primary: "#05cacc",
    primaryAlt: "#08a9f2",
    primaryHover: "#18a4a5",
    primaryLight: "#05cacc80",
    secondary: "#F6A739",
    secondaryAlt: "#F6A739",
    secondaryHover: "#F4961C",
    secondaryLight: "#f6c639",
    tertiary: "#ff6692",
    tertiaryHover: "#b3318f",
    tertiaryLight: "#ff669280",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "6",
    name: "Pink",
    primary: "#05cacc",
    primaryAlt: "#08a9f2",
    primaryHover: "#18a4a5",
    primaryLight: "#05cacc80",
    secondary: "#B01A69",
    secondaryAlt: "#B01A69",
    secondaryHover: "#EB1084",
    secondaryLight: "#EB7FB8",
    tertiary: "#ff6692",
    tertiaryHover: "#b3318f",
    tertiaryLight: "#ff669280",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
  {
    faviconUrl: "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/favicon.ico",
    id: "7",
    name: "Custom",
    primary: "#05cacc",
    primaryAlt: "#08a9f2",
    primaryHover: "#18a4a5",
    primaryLight: "#05cacc80",
    secondary: "#8032ed",
    secondaryAlt: "#1b0a33",
    secondaryHover: "#46128f",
    secondaryLight: "#b085ec",
    tertiary: "#ff6692",
    tertiaryHover: "#b3318f",
    tertiaryLight: "#ff669280",
    logoUrl:
      "https://s3-us-west-2.amazonaws.com/brite-aws-beta-assets/themes/default/logo.png",
  },
];
