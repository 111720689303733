import React from "react";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { Button } from "@brite-inc/ui-components-button";
import { Formik, Form, Field } from "formik";
import { locales } from "../../modules/i18n";
import * as Yup from "yup";
import styles from "./styles.module.scss";
import { TextInput } from "@brite-inc/ui-components-form-elements";
import { useRemoteCommand } from "@brite-inc/ui-remote";
import { useThemeContext } from "../../modules/theming/context";
import { ISubscribeForm } from "@brite-inc/ui-remote-transport-lms";
import { Spinner } from "@brite-inc/ui-components/dist/Spinner";
import { Icon } from "@brite-inc/ui-components/dist/Icon";
import { getEnv } from "../../config/getEnv";

interface IProps {
  setSentForm: (value: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

function getEnvDomain() {
  return {
    beta: ".codenow-beta.live",
    prod: ".codenow.live",
  }[getEnv()];
}

export function CreateAccountForm({ setSentForm, setIsLoading }: IProps) {
  const formatMessage = useMessageFormatter(locales.default);
  const [subDomainStatus, setSubDomainStatus] = React.useState<
    "loading" | "valid" | "invalid"
  >("invalid");

  const domainUrl = getEnvDomain();

  const { selectedThemeForm } = useThemeContext();

  const remoteCommand = useRemoteCommand();

  function submitForm({
    companyName = "",
    directorEmail = "",
    directorPhoneNumber = "",
    subdomain = "",
  }) {
    sendSubscription({
      companyName,
      directorEmail,
      directorPhoneNumber,
      domain: subdomain + domainUrl,
      theme: selectedThemeForm,
    });
  }

  async function sendSubscription(subscribeForm: ISubscribeForm) {
    setIsLoading(true);
    return remoteCommand
      .subscribe(subscribeForm)
      .then(() => {
        setSentForm(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        throw error;
      });
  }

  async function validateSubDomain(
    subdomain: string | undefined
  ): Promise<boolean> {
    setSubDomainStatus("loading");
    if (!subdomain) {
      setSubDomainStatus("invalid");
      return false;
    }

    return remoteCommand
      .checkSubdomainAvailability(subdomain + domainUrl)
      .then(({ isDomainAvailable }) => {
        if (isDomainAvailable) {
          setSubDomainStatus("valid");
        } else {
          setSubDomainStatus("invalid");
        }
        return isDomainAvailable;
      })
      .catch((err) => {
        console.error(err);
        setSubDomainStatus("invalid");
        return false;
      });
  }

  const FormikValidation = Yup.object().shape({
    companyName: Yup.string().required(formatMessage("validation.required")),
    directorEmail: Yup.string()
      .email()
      .required(formatMessage("validation.required")),
    subdomain: Yup.string()
      .required(formatMessage("validation.required"))
      .test(
        "validate-subdomain-aviability",
        formatMessage("validation.required"),
        validateSubDomain
      ),
    directorPhoneNumber: Yup.string().required(
      formatMessage("validation.required")
    ),
    terms: Yup.boolean().oneOf([true], formatMessage("validation.required")),
  });

  return (
    <Formik
      onSubmit={(value) => {
        submitForm(value);
      }}
      enableReinitialize
      initialValues={{}}
      validationSchema={FormikValidation}
      validateOnChange={false}
    >
      <Form>
        <TextInput
          name={"companyName"}
          label={"Company name"}
          placeholder={"Company name"}
          required={true}
          className={styles.input}
        />
        <TextInput
          name={"directorEmail"}
          label={"Email"}
          placeholder={"Email"}
          required={true}
          className={styles.input}
        />
        <div className={styles.subdomainWrapper}>
          <TextInput
            name={"subdomain"}
            label={"Subdomain"}
            placeholder={"Subdomain"}
            required={true}
            className={styles.input}
          />
          <span>{domainUrl}</span>
          <DomainStatus status={subDomainStatus} />
        </div>
        <TextInput
          name={"directorPhoneNumber"}
          label={"Phone number"}
          placeholder={"Phone"}
          required={true}
          className={styles.input}
        />
        <label className={styles.terms}>
          <Field type="checkbox" name="terms" />I agree to the{" "}
          <a href="https://gobrite.io/service-tc/" target={"_blank"}>
            {"terms.Terms"}
          </a>{" "}
          and Conditions and{" "}
          <a href="https://gobrite.io/privacy-policy/" target={"_blank"}>
            Privacy Statement
          </a>
          .
        </label>
        <Button
          type="submit"
          variant={"filled"}
          color="primary"
          className={styles.sendButton}
        >
          Create
        </Button>
      </Form>
    </Formik>
  );
}

function DomainStatus({ status }: { status: string }) {
  if (status === "loading") {
    return <Spinner className={styles.spinner} />;
  } else if (status === "invalid") {
    return <Icon name={"x"} className={styles.invalidIcon} />;
  }
  return <Icon name={"check"} className={styles.validIcon} />;
}
