import * as React from "react";
import cn from "classnames";
import {
  Enrollment,
  RenderCourse,
} from "@brite-inc/ui-components/dist/Enrollment";
import { Button } from "@brite-inc/ui-components/dist/Button";
import { ProgressBar } from "@brite-inc/ui-components/dist/ProgressBar";
import {IEnrollmentRank, ITheme} from "@brite-inc/ui-remote-transport-lms";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import defaultLogoImage from "./defaultLogo.png";
import defaultUserAvatar from "./defaultUserAvatar.png";
import styles from "./styles.module.scss";
import {
  convertThemeToCssVariabels,
  getCourseCompletedProjects,
} from "./utils";
import { ranks } from "./constans";
import {IEnrollmentRankCourse} from "@brite-inc/ui-remote-transport-lms/dist/response/GetStudentEnrollmentList";

interface IProps {
  theme: ITheme;
  enrollmentBackgroundColorTransitionEnabled: boolean;
  logo?: string;
  avatar?: string;
}

export function ThemePreview({
  theme,
  enrollmentBackgroundColorTransitionEnabled,
  logo,
  avatar,
}: IProps) {
  const formatMessage = useMessageFormatter(locales.default);

  const cssVariables = React.useMemo(
    () => convertThemeToCssVariabels(theme),
    [theme]
  );

  const renderCourse: RenderCourse<IEnrollmentRankCourse, IEnrollmentRank> = (course) => {
    return (
      <div className={styles.rankCourse} key={course.courseId}>
        <div className={styles.rankCourseName}>{course.shortTitle}</div>
        <div className={styles.rankCourseProgress}>
          {getCourseCompletedProjects(course).length} / {course.projectCount}{" "}
          {formatMessage("themePreview.completed")}
          <ProgressBar
            value={
              course.enrollment?.progress &&
              Math.floor(course.enrollment.progress)
            }
            className={styles.rankCourseProgressBar}
            variant="primary"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={styles.themePreview}
      style={cssVariables}
      data-testid="theme-preview"
    >
      <div className={styles.topbarLogo}>
        {logo ? (
          <img
            src={logo}
            alt={formatMessage("themePreview.logo")}
            className={styles.topbarLogo__Image}
          />
        ) : (
          <img
            src={defaultLogoImage}
            alt={formatMessage("themePreview.logo")}
            className={styles.topbarLogo__Image}
          />
        )}
      </div>
      <div className={styles.topbarContent}>
        <div className={styles.topbarContent__Content}>
          <div className={styles.topbarContentPageTitle}>
            {formatMessage("themePreview.dashboard")}
          </div>
          <div className={styles.topbarContentUser}>
            <div className={styles.topbarContentUserDetails}>
              <h4 className={styles.topbarContentUserDetailsName}>
                {formatMessage("themePreview.johnDoe")}
              </h4>
              <span className={styles.topbarContentUserDetailsRole}>
                {formatMessage("themePreview.student")}
              </span>
            </div>
            <div
              className={cn(
                styles.topbarContentUserAvatar,
                !avatar && styles.topbarContentUserAvatarEmpty
              )}
            >
              {avatar ? (
                <img
                  src={avatar}
                  alt={formatMessage("themePreview.userAvatar")}
                  className={styles.topbarContentUserAvatar__Image}
                />
              ) : (
                <img
                  src={defaultUserAvatar}
                  alt={formatMessage("themePreview.userAvatar")}
                  className={styles.topbarContentUserAvatar__Image}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.sidebar}>
        <ul className={styles.sidebarMenu}>
          <li
            className={cn(styles.sidebarMenuItem, styles.sidebarMenuItemActive)}
          >
            {formatMessage("themePreview.dashboard")}
          </li>
          <li className={styles.sidebarMenuItem}>
            {formatMessage("themePreview.myAchievements")}
          </li>
          <li className={styles.sidebarMenuItem}>
            {formatMessage("themePreview.profileSettings")}
          </li>
          <li className={styles.sidebarMenuItem}>
            {formatMessage("themePreview.projectsForHome")}
          </li>
          <li
            className={cn(styles.sidebarMenuItem, styles.sidebarMenuItemLogout)}
          >
            {formatMessage("themePreview.logout")}
          </li>
        </ul>
      </div>
      <div className={styles.content}>
        <div className={styles.ranks}>
          <Enrollment
            renderCourse={renderCourse}
            ranks={ranks}
            displayRanks={true}
            displayRankImages={true}
            openRankIds={["1"]}
            coursesWrapperClassName={styles.rankCourses}
            backgroundColorTransition={
              enrollmentBackgroundColorTransitionEnabled
            }
          />
        </div>

        <Button
          variant="filled"
          color="tertiary"
          type="button"
          className={styles.feedback}
        >
          {formatMessage("themePreview.feedback")}
        </Button>
      </div>
    </div>
  );
}
