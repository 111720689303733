import { Modal } from "@brite-inc/ui-components/dist/Modal";
import ImageEditor from "../ImageEditor";
import styles from "./styles.module.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  recommendedSize: string;
  aspectRatio?: number;
  onSubmit: ({ image, url }: { image: string; url: string }) => void;
}

export function UploadImageModal({
  title,
  description,
  recommendedSize,
  aspectRatio,
  isOpen,
  onClose,
  onSubmit,
}: IProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className={styles.modal}
    >
      <div className={styles.content}>
        <p className={styles.description}>{description}</p>
        <p className={styles.recommendedSize}>{recommendedSize}</p>
        <ImageEditor aspectRatio={aspectRatio} onSubmit={onSubmit} />
      </div>
    </Modal>
  );
}
