import * as React from "react";
import { Link } from "react-router-dom";
import { ITheme } from "@brite-inc/ui-remote-transport-lms";
import {
  generateThemeColors,
  generateThemeQueryString,
} from "@brite-inc/ui-components/dist/theme";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import { useThemeContext } from "../../modules/theming/context";
import { themes as initialThemes } from "../../modules/theming/constants";
import { Topbar } from "../../components/Topbar";
import { ThemeSelector } from "../../components/ThemeSelector";
import { ThemePreview } from "../../components/ThemePreview";
import { ThemeColorsPreview } from "../../components/ThemeColorsPreview";
import { UploadImageModal } from "../../components/UploadImageModal";
import styles from "./styles.module.scss";

export function ThemeSelectorPage() {
  const [
    enrollmentBackgroundColorTransitionEnabled,
    setEnrollmentBackgroundColorTransitionEnabled,
  ] = React.useState(true);
  const [logoUploadModalVisible, setLogoUploadModalVisible] =
    React.useState(false);
  const [avatarUploadModalVisible, setAvatarUploadModalVisible] =
    React.useState(false);
  const [selectedThemeId, setSelectedThemeId] = React.useState("1");
  const [themes, setThemes] = React.useState(initialThemes);
  const theme = themes.find((t) => t.id === selectedThemeId);
  const {
    logo,
    avatar,
    logoUrl,
    avatarUrl,
    selectedThemeForm,
    onThemeQueryStringSelect,
    onLogoSelect,
    onAvatarSelect,
    onLogoUrlSelect,
    onAvatarUrlSelect,
    setSelectedThemeForm,
  } = useThemeContext();

  const formatMessage = useMessageFormatter(locales.default);

  const onLogoUpload = (logo: string, logoUrl: string) => {
    onLogoSelect(logo);
    onLogoUrlSelect(logoUrl);

    setSelectedThemeForm({
      ...selectedThemeForm,
      logo: logoUrl,
    });
  };

  const onAvatarUpload = (avatar: string, avatarUrl: string) => {
    onAvatarSelect(avatar);
    onAvatarUrlSelect(avatarUrl);

    setSelectedThemeForm({
      ...selectedThemeForm,
      avatar: avatarUrl,
    });
  };

  const handleSelectedThemeChange = (themeId: string) => {
    setEnrollmentBackgroundColorTransitionEnabled(false);
    setSelectedThemeId(themeId);

    setTimeout(() => {
      setEnrollmentBackgroundColorTransitionEnabled(true);
    }, 150);
  };

  const handleThemeColorChange = (changedTheme: ITheme) => {
    const selectedTheme = themes.find((t) => t.id === selectedThemeId);
    const customThemeIndex = themes.findIndex((t) => t.name === "Custom");
    const updatedCustomTheme = {
      ...themes[customThemeIndex],
      ...generateThemeColors({
        primary: changedTheme.primary,
        secondary: changedTheme.secondary,
        tertiary: changedTheme.tertiary,
      }),
    };

    const newThemes = [
      ...themes.slice(0, customThemeIndex),
      updatedCustomTheme,
      ...themes.slice(customThemeIndex + 1),
    ];

    setThemes(newThemes);

    if (selectedTheme!.name !== "Custom") {
      setSelectedThemeId(themes[customThemeIndex].id);
    }
  };

  React.useEffect(() => {
    const themeQueryString = generateThemeQueryString(
      theme,
      logoUrl,
      avatarUrl
    );

    setSelectedThemeForm({
      ...selectedThemeForm,
      primaryColor: theme?.primary || "",
      secondaryColor: theme?.secondary || "",
      tertiaryColor: theme?.tertiary || "",
    });

    if (!themeQueryString) {
      return;
    }

    onThemeQueryStringSelect(themeQueryString);
  }, [selectedThemeId, onThemeQueryStringSelect, theme, logoUrl, avatarUrl]);

  return (
    <div className={styles.page}>
      <Topbar>
        <span className={styles.pageTitle}>
          {formatMessage("themeSelectorPage.selectTheme")}
        </span>
      </Topbar>

      <div className={styles.content}>
        <div className={styles.pageBlock}>
          <div className={styles.pageBlockTopbar}>
            <h4 className={styles.title}>
              {formatMessage("themeSelectorPage.selectThemeFromTheListBelow")}
            </h4>
          </div>

          <div className={styles.uploadButtons}>
            <button
              type="button"
              className={styles.button}
              onClick={() => setLogoUploadModalVisible(true)}
            >
              {formatMessage("themeSelectorPage.addYourLogoHere")}
            </button>

            <button
              type="button"
              className={styles.button}
              onClick={() => setAvatarUploadModalVisible(true)}
            >
              {formatMessage("themeSelectorPage.addYourAvatarHere")}
            </button>
          </div>

          <div className={styles.themeSelector}>
            <ThemeSelector
              themes={themes.map((theme) => ({
                id: theme.id,
                name: theme.name,
              }))}
              themeId={selectedThemeId}
              onChange={handleSelectedThemeChange}
            />
          </div>

          <p className={styles.text}>
            {formatMessage("themeSelectorPage.clickOnColorsBelowToCustomize")}
          </p>

          <div className={styles.themeColorsPreview}>
            <ThemeColorsPreview
              theme={theme!}
              onChange={handleThemeColorChange}
            />
          </div>

          <div className={styles.livePreviewLink}>
            <Link
              to={`/live-preview`}
              className={styles.link}
            >
              {formatMessage("themeSelectorPage.goToLivePreview")}
            </Link>
          </div>
        </div>

        <div className={styles.pageBlock}>
          <div className={styles.pageBlockTopbar}>
            <h4 className={styles.title}>
              {formatMessage("themeSelectorPage.visualPreview")}
            </h4>
            <p className={styles.text}>
              {formatMessage(
                "themeSelectorPage.youCanChangeTheseColorsToYourOwnPreference"
              )}
            </p>
          </div>

          <div className={styles.themePreview}>
            <ThemePreview
              theme={theme!}
              enrollmentBackgroundColorTransitionEnabled={
                enrollmentBackgroundColorTransitionEnabled
              }
              logo={logo}
              avatar={avatar}
            />
          </div>
        </div>
      </div>

      <UploadImageModal
        isOpen={logoUploadModalVisible}
        onClose={() => {
          setLogoUploadModalVisible(false);
        }}
        onSubmit={({ image, url }) => {
          onLogoUpload(image, url);
          setLogoUploadModalVisible(false);
        }}
        title={formatMessage("themeSelectorPage.uploadLogo")}
        description={formatMessage(
          "themeSelectorPage.uploadImageThenCropResizeItToTheRecommendedSize"
        )}
        recommendedSize={formatMessage(
          "themeSelectorPage.recommendedSizeForLogo"
        )}
        aspectRatio={16 / 9}
      />

      <UploadImageModal
        isOpen={avatarUploadModalVisible}
        onClose={() => {
          setAvatarUploadModalVisible(false);
        }}
        onSubmit={({ image, url }) => {
          onAvatarUpload(image, url);
          setAvatarUploadModalVisible(false);
        }}
        title={formatMessage("themeSelectorPage.uploadUserAvatar")}
        description={formatMessage(
          "themeSelectorPage.uploadImageThenCropResizeItToTheRecommendedSize"
        )}
        recommendedSize={formatMessage(
          "themeSelectorPage.recommendedSizeForUserAvatar"
        )}
        aspectRatio={1}
      />
    </div>
  );
}

export default ThemeSelectorPage;
