import * as React from "react";
import { Formik } from "formik";
import { ITheme } from "@brite-inc/ui-remote-transport-lms";
import { Dropdown } from "@brite-inc/ui-components/dist/Dropdown";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import { ThemeColorEditor } from "../ThemeColorEditor";
import styles from "./styles.module.scss";

interface IProps {
  theme: ITheme;
  onChange: (theme: ITheme) => void;
}

export function ThemeColorsPreview({ theme, onChange }: IProps) {
  const [selectedColor, setSelectedColor] = React.useState<string | null>(null);
  const formatMessage = useMessageFormatter(locales.default);
  const primaryColorToggleRef = React.useRef(null);
  const secondaryColorToggleRef = React.useRef(null);
  const tertiaryColorToggleRef = React.useRef(null);

  return (
    <Formik
      initialValues={{
        primary: theme.primary,
        secondary: theme.secondary,
        tertiary: theme.tertiary,
      }}
      onSubmit={() => {}}
      enableReinitialize
    >
      {({ values, setValues }) => (
        <div className={styles.preview}>
          <div className={styles.color}>
            <span className={styles.colorName}>
              {formatMessage("themeColorsPreview.primary")}
            </span>
            <div className={styles.colorDetails}>
              <button
                type="button"
                className={styles.colorBox}
                style={{ backgroundColor: values.secondary }}
                onClick={() => setSelectedColor("primary")}
                ref={primaryColorToggleRef}
              />
              <span className={styles.colorValue}>{values.secondary}</span>
            </div>

            {selectedColor === "primary" ? (
              <Dropdown
                targetRef={primaryColorToggleRef}
                onClose={() => setSelectedColor(null)}
                dropdownContentClassName={styles.colorDropdownContent}
              >
                <ThemeColorEditor
                  value={values.secondary}
                  onSubmit={(color) => {
                    setValues({
                      ...values,
                      secondary: color,
                    });
                    onChange({
                      ...theme,
                      secondary: color,
                    });
                    setSelectedColor(null);
                  }}
                />
              </Dropdown>
            ) : null}
          </div>

          <div className={styles.color}>
            <span className={styles.colorName}>
              {formatMessage("themeColorsPreview.secondary")}
            </span>
            <div className={styles.colorDetails}>
              <button
                type="button"
                className={styles.colorBox}
                style={{ backgroundColor: values.primary }}
                onClick={() => setSelectedColor("secondary")}
                ref={secondaryColorToggleRef}
              />
              <span className={styles.colorValue}>{values.primary}</span>
            </div>

            {selectedColor === "secondary" ? (
              <Dropdown
                targetRef={secondaryColorToggleRef}
                onClose={() => setSelectedColor(null)}
                dropdownContentClassName={styles.colorDropdownContent}
              >
                <ThemeColorEditor
                  value={values.primary}
                  onSubmit={(color) => {
                    setValues({
                      ...values,
                      primary: color,
                    });
                    onChange({
                      ...theme,
                      primary: color,
                    });
                    setSelectedColor(null);
                  }}
                />
              </Dropdown>
            ) : null}
          </div>

          <div className={styles.color}>
            <span className={styles.colorName}>
              {formatMessage("themeColorsPreview.tertiary")}
            </span>
            <div className={styles.colorDetails}>
              <button
                type="button"
                className={styles.colorBox}
                style={{ backgroundColor: values.tertiary }}
                onClick={() => setSelectedColor("tertiary")}
                ref={tertiaryColorToggleRef}
              />
              <span className={styles.colorValue}>{values.tertiary}</span>
            </div>

            {selectedColor === "tertiary" ? (
              <Dropdown
                targetRef={tertiaryColorToggleRef}
                onClose={() => setSelectedColor(null)}
                dropdownContentClassName={styles.colorDropdownContent}
              >
                <ThemeColorEditor
                  value={values.tertiary}
                  onSubmit={(color) => {
                    setValues({
                      ...values,
                      tertiary: color,
                    });
                    onChange({
                      ...theme,
                      tertiary: color,
                    });
                    setSelectedColor(null);
                  }}
                />
              </Dropdown>
            ) : null}
          </div>
        </div>
      )}
    </Formik>
  );
}
