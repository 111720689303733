import * as React from "react";
import { ChromePicker } from "react-color";
import { Button } from "@brite-inc/ui-components/dist/Button";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import styles from "./styles.module.scss";

interface IProps {
  value: string;
  onSubmit: (color: string) => void;
}

export function ThemeColorEditor({ value, onSubmit }: IProps) {
  const [color, setColor] = React.useState(value);
  const formatMessage = useMessageFormatter(locales.default);

  return (
    <form onSubmit={() => onSubmit(color)}>
      <ChromePicker
        color={color}
        onChange={(nextColor) => setColor(nextColor.hex)}
      />
      <Button
        variant="filled"
        color="primary"
        type="button"
        className={styles.submitButton}
        onClick={() => onSubmit(color)}
      >
        {formatMessage("themeColorEditor.setColor")}
      </Button>
    </form>
  );
}
