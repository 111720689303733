export function getEnv(): "local" | "beta" | "prod" {
  if (window.location.hostname === "localhost") {
    return "local";
  }
  return window.location.host.includes("beta.io") ? "beta" : "prod";
}

export function getLivePreviewUrl() {
  switch (getEnv()) {
    case "local":
      return "http://localhost:3000";
    case "beta":
      return "https://preview.gobrite-beta.io";
    case "prod":
      return "https://preview.gobrite.io";
  }
}
