import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import styles from "./styles.module.scss";

interface ITheme {
  id: string;
  name: string;
}

interface IProps {
  themes: ITheme[];
  themeId: string;
  onChange: (themeId: string) => void;
}

export function ThemeSelector({ themes, themeId, onChange }: IProps) {
  const formatMessage = useMessageFormatter(locales.default);

  return (
    <div className={styles.themeSelector}>
      <form>
        <label htmlFor="theme" className={styles.label}>
          {formatMessage("themeSelector.theme")}
        </label>
        <select
          onChange={(e) => onChange(e.target.value)}
          className={styles.select}
          value={themeId}
        >
          {themes.map((theme) => (
            <option key={theme.id} value={theme.id}>
              {theme.name}
            </option>
          ))}
        </select>
      </form>
    </div>
  );
}
