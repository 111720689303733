import * as React from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useNotification } from "@brite-inc/ui-hooks/dist/notifications";
import { Button } from "@brite-inc/ui-components/dist/Button";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import { getApiDomain } from "../../config/getApiDomain";
import styles from "./styles.module.scss";

interface IProps {
  zoomable?: boolean;
  scalable?: boolean;
  aspectRatio?: number;
  onSubmit: ({ image, url }: { image: string; url: string }) => void;
}

const ApiDomain = getApiDomain();

export default function ImageEditor({
  zoomable = true,
  scalable = true,
  aspectRatio = 1,
  onSubmit,
}: IProps) {
  const [uploadedImage, setUploadedImage] = React.useState("");
  const [finalImage, setFinalImage] = React.useState("");
  const [finalImageBlob, setFinalImageBlob] = React.useState<Blob | null>(null);
  const [isUploading, setUploading] = React.useState(false);
  const uploadedImageRef = React.useRef(null);

  const formatMessage = useMessageFormatter(locales.default);
  const notification = useNotification();

  const onDrop = React.useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setUploadedImage(reader.result! as string)
      );
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleFileUpload = async () => {
    setUploading(true);

    axios
      .post(`${ApiDomain}/upload`, finalImageBlob, {
        headers: {
          "Content-Type": "image/png",
        },
      })
      .then((response) => {
        onSubmit?.({ image: finalImage, url: response.data.publicUrl });
        setUploading(false);
      })
      .catch(() => {
        notification.error(formatMessage("imageEditor.errors.uploading"));
        setUploading(false);
      });
  };

  React.useEffect(() => {
    if (!uploadedImage || !uploadedImageRef.current) {
      return;
    }

    const cropper = new Cropper(uploadedImageRef.current, {
      zoomable,
      scalable,
      aspectRatio,
      center: true,
      crop: () => {
        const canvas = cropper.getCroppedCanvas();
        setFinalImage(canvas.toDataURL("image/png"));

        canvas.toBlob((blob) => {
          setFinalImageBlob(blob);
        });
      },
    });

    return () => {
      cropper.destroy();
    };
  }, [uploadedImage, zoomable, scalable, aspectRatio]);

  const showContent = !!uploadedImage;

  return (
    <div className={styles.imageEditor}>
      <div className={styles.imageFileDropzone} {...getRootProps()}>
        <input {...getInputProps()} accept="image/*" />

        <div className={styles.imageFileDropzoneText}>
          {isDragActive ? (
            <p>{formatMessage("imageEditor.dropTheFileHere")}</p>
          ) : (
            <p>
              {formatMessage("imageEditor.dndFileHereOrClickToSelectAFile")}
            </p>
          )}
        </div>
      </div>

      {showContent ? (
        <div className={styles.content}>
          {uploadedImage ? (
            <div className={styles.editor}>
              <p className={styles.helpText}>
                {formatMessage("imageEditor.adjustImageSize")}
              </p>

              <div className={styles.imageWrapper}>
                <img
                  ref={uploadedImageRef}
                  src={uploadedImage}
                  alt={formatMessage("imageEditor.uploadedImage")}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={styles.footer}>
        <Button
          variant="filled"
          color="secondary"
          type="button"
          className={styles.button}
          onClick={handleFileUpload}
          disabled={!finalImage || isUploading}
        >
          {isUploading
            ? formatMessage("imageEditor.uploading")
            : formatMessage("imageEditor.upload")}
        </Button>
      </div>
    </div>
  );
}
