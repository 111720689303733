import {
  IEnrollmentRankCourse,
  ITheme,
} from "@brite-inc/ui-remote-transport-lms";

export function convertThemeToCssVariabels(theme: ITheme) {
  return Object.entries(theme).reduce((acc, cur) => {
    return {
      ...acc,
      [`--${cur[0]}`]: cur[1],
    };
  }, {});
}

export function getCourseCompletedProjects(course: IEnrollmentRankCourse) {
  const projects = course.enrollment?.projects ?? [];
  return projects.filter((p) => p.progress === 100);
}
