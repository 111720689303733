import {
  CourseType,
  IEnrollmentRank,
  ProjectContentDeliveryType,
} from "@brite-inc/ui-remote-transport-lms";

export const ranks: IEnrollmentRank[] = [
  {
    courses: [
      {
        courseId: "1",
        enrollment: {
          enrollmentId: "1",
          progress: 16.666666666666668,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 0 },
            { id: "3", progress: 0 },
            { id: "3", progress: 0 },
            { id: "4", progress: 0 },
            { id: "5", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.131Z",
        },
        projectCount: 6,
        shortTitle: "Scratch 1",
        title: "Scratch 1",
        contentDeliveryType: ProjectContentDeliveryType.IframeScratch,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "2",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
            { id: "3", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 3,
        shortTitle: "Scratch 2",
        title: "Scratch 2",
        contentDeliveryType: ProjectContentDeliveryType.IframeScratch,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "3",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Scratch 3",
        title: "Scratch 3",
        contentDeliveryType: ProjectContentDeliveryType.IframeScratch,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "4",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "1", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Scratch 4",
        title: "Scratch 4",
        contentDeliveryType: ProjectContentDeliveryType.IframeScratch,
        courseType: CourseType.Chapter,
      },
    ],
    icon: "/images/rank-1.png",
    rankId: "1",
    title: "Rank 1",
  },
  {
    courses: [
      {
        courseId: "1",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
            { id: "3", progress: 100 },
            { id: "4", progress: 100 },
            { id: "5", progress: 100 },
            { id: "6", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.131Z",
        },
        projectCount: 6,
        shortTitle: "Roblox 1",
        title: "Roblox",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "2",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
            { id: "3", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 3,
        shortTitle: "Roblox 2",
        title: "Roblox",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "3",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Roblox 3",
        title: "Roblox",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "4",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Roblox 4",
        title: "Roblox",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
    ],
    icon: "/images/rank-2.png",
    rankId: "2",
    title: "Rank 2",
  },
  {
    courses: [
      {
        courseId: "1",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
            { id: "3", progress: 100 },
            { id: "4", progress: 100 },
            { id: "5", progress: 100 },
            { id: "6", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.131Z",
        },
        projectCount: 6,
        shortTitle: "Minecraft 1",
        title: "Minecraft",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "2",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
            { id: "3", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 3,
        shortTitle: "Minecraft 2",
        title: "Minecraft",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "3",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Minecraft 3",
        title: "Minecraft",
        contentDeliveryType: ProjectContentDeliveryType.Iframe,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "4",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "Minecraft 4",
        title: "Minecraft",
        contentDeliveryType: ProjectContentDeliveryType.Link,
        courseType: CourseType.Link,
      },
    ],
    icon: "/images/rank-3.png",
    rankId: "3",
    title: "Rank 3",
  },
  {
    courses: [
      {
        courseId: "1",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
            { id: "3", progress: 100 },
            { id: "4", progress: 100 },
            { id: "5", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.131Z",
        },
        projectCount: 5,
        shortTitle: "JavaScript 1",
        title: "JavaScript",
        contentDeliveryType: ProjectContentDeliveryType.Chapter,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "2",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
            { id: "3", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 3,
        shortTitle: "JavaScript 2",
        title: "JavaScript",
        contentDeliveryType: ProjectContentDeliveryType.Chapter,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "3",
        enrollment: {
          enrollmentId: "1",
          progress: 0,
          projects: [
            { id: "1", progress: 0 },
            { id: "2", progress: 0 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "JavaScript 3",
        title: "JavaScript",
        contentDeliveryType: ProjectContentDeliveryType.Chapter,
        courseType: CourseType.Chapter,
      },
      {
        courseId: "4",
        enrollment: {
          enrollmentId: "1",
          progress: 100,
          projects: [
            { id: "1", progress: 100 },
            { id: "2", progress: 100 },
          ],
          startDate: "2021-07-25T20:26:39.132Z",
        },
        projectCount: 2,
        shortTitle: "JavaScript 4",
        title: "JavaScript",
        contentDeliveryType: ProjectContentDeliveryType.Chapter,
        courseType: CourseType.Chapter,
      },
    ],
    icon: "/images/rank-4.png",
    rankId: "4",
    title: "Rank 4",
  },
];
