import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { setupIcons } from "@brite-inc/ui-components/dist/Icon";
import { NotificationsContainer } from "@brite-inc/ui-hooks/dist/notifications";
import { ThemeInitializer } from "@brite-inc/ui-components/dist/theme/ThemeInitializer";
import { ThemeContextProvider } from "./modules/theming/context";
import { ICONS } from "./modules/icons";
import { themes } from "./modules/theming/constants";
import { LivePreviewPage } from "./pages/LivePreviewPage";
import { ThemeSelectorPage } from "./pages/ThemeSelectorPage";

setupIcons(ICONS);

function App() {
  return (
    <BrowserRouter>
      <NotificationsContainer />
      <Switch>
        <ThemeInitializer themes={themes}>
          {() => (
            <ThemeContextProvider>
              <Route
                path="/select-theme"
                exact={true}
                component={ThemeSelectorPage}
              />
              <Route
                path="/live-preview"
                exact={true}
                component={LivePreviewPage}
              />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/select-theme" />}
              />
            </ThemeContextProvider>
          )}
        </ThemeInitializer>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
