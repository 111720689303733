import * as React from "react";
import { Link } from "react-router-dom";
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@brite-inc/ui-components/dist/Tabs";
import { Icon } from "@brite-inc/ui-components/dist/Icon";
import { Button } from "@brite-inc/ui-components/dist/Button";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import { useThemeContext } from "../../modules/theming/context";
import { Topbar } from "../../components/Topbar";
import { LivePreview } from "../../components/LivePreview";
import { getLivePreviewUrl } from "../../utils";
import styles from "./styles.module.scss";
import { CreateAccountModal } from "../../components/CreateAccountModal";
import {
  RemoteCommandBackendImpl,
  RemoteCommandProvider,
} from "@brite-inc/ui-remote";
import { getApiDomain } from "../../config/getApiDomain";

export function LivePreviewPage() {
  const [contactFormModalVisible, setContactFormModalVisible] =
    React.useState(false);
  const { themeQueryString } = useThemeContext();
  const formatMessage = useMessageFormatter(locales.default);
  const livePreviewUrl = getLivePreviewUrl();

  const remoteCommand = new RemoteCommandBackendImpl(getApiDomain(), "");
  return (
    <div className={styles.page}>
      <Topbar>
        <div className={styles.topbar}>
          <span className={styles.pageTitle}>
            <Link
              to={`/select-theme`}
              className={styles.pageTitleLink}
            >
              <Icon name="arrow-left" />
            </Link>
            {formatMessage("livePreviewPage.livePreview")}
          </span>

          <span className={styles.pageTitleApplyDesignText}>
            {formatMessage(
              "livePreviewPage.ifYourLikeAppDesignPressApplyButton"
            )}
          </span>
          <Button
            variant="filled"
            color="tertiary"
            type="button"
            className={styles.button}
            onClick={() => setContactFormModalVisible(true)}
          >
            {formatMessage("livePreviewPage.apply")}
          </Button>
        </div>
      </Topbar>

      <div className={styles.content}>
        <Tabs>
          <TabList className={styles.tabList}>
            <Tab className={styles.tab}>
              {formatMessage("livePreviewPage.studentDashboard")}
            </Tab>
            <Tab className={styles.tab}>
              {formatMessage("livePreviewPage.adminDashboard")}
            </Tab>
          </TabList>

          <TabPanels className={styles.tabPanels}>
            <TabPanel className={styles.tabPanel}>
              <div className={styles.preview}>
                <LivePreview
                  title={formatMessage("livePreviewPage.livePreview")}
                  url={`${livePreviewUrl}?loginAs=student${themeQueryString}`}
                />
              </div>
            </TabPanel>
            <TabPanel className={styles.tabPanel}>
              <div className={styles.preview}>
                <LivePreview
                  title={formatMessage("livePreviewPage.livePreview")}
                  url={`${livePreviewUrl}?loginAs=admin${themeQueryString}`}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>

      <RemoteCommandProvider remoteCommand={remoteCommand}>
        <CreateAccountModal
          isOpen={contactFormModalVisible}
          onClose={() => {
            setContactFormModalVisible(false);
          }}
        />
      </RemoteCommandProvider>
    </div>
  );
}

export default LivePreviewPage;
