import * as React from "react";
import styles from "./styles.module.scss";

interface IProps {
  children?: React.ReactNode;
}

export function Topbar({ children }: IProps) {
  return <div className={styles.topbar}>{children}</div>;
}
