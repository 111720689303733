import React from "react";
import { Modal } from "@brite-inc/ui-components/dist/Modal";
import { useMessageFormatter } from "@brite-inc/ui-hooks/dist/i18n";
import { locales } from "../../modules/i18n";
import styles from "./styles.module.scss";
import { CreateAccountForm } from "../CreateAccountForm";
import SentImage from "./icons/sent.svg";
import { Button } from "@brite-inc/ui-components-button";
import { Spinner } from "@brite-inc/ui-components/dist/Spinner";
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CreateAccountModal({ isOpen, onClose }: IProps) {
  const formatMessage = useMessageFormatter(locales.default);
  const [sentForm, setSentForm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={sentForm ? "" : formatMessage("createAccountFormModal.createAccount")}
      className={`${styles.modal} ${sentForm ? styles.sent : ""}`}
    >
      {isLoading ? 
      <Spinner />
      : sentForm ? (
        <div>
          <img src={SentImage} className={styles.sentIcon} />
          <h3 className={styles.messageTitle}>{formatMessage("createAccountFormModal.thank")}</h3>
          <p className={styles.messageText}>{formatMessage("createAccountFormModal.creatingAccount")}</p>
          <Button
            variant={"filled"}
            color="primary"
            className={styles.finishButton}
            onClick={() => {
              onClose();
              setSentForm(false);
            }}
          >
            {formatMessage("createAccountFormModal.finish")}
          </Button>
        </div>
      ) : (
        <CreateAccountForm setSentForm={setSentForm} setIsLoading={setIsLoading}/>
      )}
    </Modal>
  );
}
