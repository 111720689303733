import * as React from "react";
import cn from "classnames";
import { Spinner } from "@brite-inc/ui-components/dist/Spinner";
import styles from "./styles.module.scss";

interface IProps {
  url: string;
  title: string;
}

export function LivePreview({ url, title }: IProps) {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <div className={styles.livePreview}>
      {!loaded ? <Spinner /> : null}

      <iframe
        className={cn(styles.iframe, !loaded && styles.iframeHidden)}
        frameBorder="0"
        src={url}
        title={title}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
}
